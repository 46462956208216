/**
 * Function to generate a cover letter using OpenAI GPT-4.
 * @param {string} jobDescription - The job description input by the user.
 * @returns {Promise<string>} The generated cover letter content.
 */
export const generateCoverLetter = async (jobDescription, openai) => {
  try {
    const response = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      messages: [
        {
          role: "system", content: `When a user asks for a cover letter, write based on the following rules:
- The first sentence must be a self introduction and a simple sentence. Stop using "I am writing to express..."
- You should use as few complex and subordinate clauses as possible.
- You should not generate anything before the user puts in the job description.
- You should follow the strategy in the writing guide: Writing_Guide.txt
- The cover letter can be directly used and does not need to change anything. Do not use any placeholders.
- Remove the opening and closing greetings.
` },
        {
          role: "user",
          content: `Job description: ${jobDescription}`,
        },
      ],
    })

    const generatedText = response.choices[0].message.content
    // console.log("Generated cover letter:", generatedText)
    return generatedText
  } catch (error) {
    console.error("Error generating cover letter:", error)
    throw new Error("Failed to generate cover letter.")
  }
}