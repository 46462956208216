import React, { useState, useEffect } from 'react'
import { generateCoverLetter } from '../utils/coverletterGenerator'
import Cookies from 'js-cookie'
import {
  embedContentInTemplate,
  generatePDFFromLatex,
} from '../utils/pdfGenerator'
import { useOpenAI } from '../context/OpenAIKeyContext'
import CoverLetterForm from './CoverLetterForm' // 导入表单组件
import { getLatexStr } from '../templates/templates'

const JobDescriptionProcessor = ({ PDFOnly }) => {
  const [jobDescription, setJobDescription] = useState('')
  const [processedResult, setProcessedResult] = useState('')
  const { openai, setApiKey } = useOpenAI()

  const [formData, setFormData] = useState(() => {
    const savedData = Cookies.get('coverLetterFormData')
    return savedData
      ? JSON.parse(savedData)
      : {
          name: '',
          phone: '',
          email: '',
          linkedin: '',
          github: '',
          role: '',
          hm: 'Hiring Manager',
          portfolio: '',
          fontSize: '12pt',
          lineHeight: '20pt',
          content: '',
        }
  })

  // Save formData to Cookies whenever it changes
  useEffect(() => {
    Cookies.set('coverLetterFormData', JSON.stringify(formData), { expires: 7 })
  }, [formData])

  const handleProcessJobDescription = async () => {
    console.log(PDFOnly)
    if (PDFOnly) {
      alert('You need to set your OpenAI API key to use this feature')
      return
    }
    const result = await generateCoverLetter(jobDescription, openai)
    setProcessedResult(result)
  }

  const handleGeneratePDF = async () => {
    const latex = getLatexStr({ ...formData, content: processedResult })
    await generatePDFFromLatex(latex)
  }

  const handleRemoveApiKey = () => {
    console.log('Removing API key')
    setApiKey('')
    Cookies.remove('openai_api_key')
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}>
        {!PDFOnly && (
          <textarea
            style={{ width: '48%', height: '500px' }}
            value={jobDescription}
            onChange={(e) => setJobDescription(e.target.value)}
            placeholder="Enter job description"
          />
        )}
        <textarea
          style={{ width: '48%', height: '500px' }}
          value={processedResult}
          onChange={(e) => setProcessedResult(e.target.value)}
          placeholder="Processed result"
        />
      </div>
      <div style={{ marginTop: '20px', width: '100%' }}>
        <CoverLetterForm formData={formData} setFormData={setFormData} />
        {!PDFOnly && (
          <button
            onClick={handleProcessJobDescription}
            style={{ marginRight: '10px' }}>
            Process Job Description
          </button>
        )}
        <button onClick={handleGeneratePDF}>Generate PDF</button>
        {!PDFOnly && (
          <button onClick={handleRemoveApiKey} style={{ marginLeft: '10px' }}>
            Remove API Key
          </button>
        )}
      </div>
    </div>
  )
}

export default JobDescriptionProcessor
