import React from 'react'

const CoverLetterForm = ({ formData, setFormData }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target
    let updatedValue = value

    // Remove http, https, and www. prefix for LinkedIn and GitHub fields
    if (name === 'linkedin' || name === 'github') {
      updatedValue = value
        .replace(/^https?:\/\//, '') // Remove http:// or https://
        .replace(/^www\./, '') // Remove www.
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }))
  }

  return (
    <form style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <input
        type="text"
        name="name"
        value={formData.name}
        onChange={handleInputChange}
        placeholder="Your Name"
      />
      <input
        type="text"
        name="phone"
        value={formData.phone}
        onChange={handleInputChange}
        placeholder="Your Phone"
      />
      <input
        type="email"
        name="email"
        value={formData.email}
        onChange={handleInputChange}
        placeholder="Your Email"
      />
      <input
        type="text"
        name="linkedin"
        value={formData.linkedin}
        onChange={handleInputChange}
        placeholder="LinkedIn"
      />
      <input
        type="text"
        name="github"
        value={formData.github}
        onChange={handleInputChange}
        placeholder="GitHub"
      />
      <input
        type="text"
        name="role"
        value={formData.role}
        onChange={handleInputChange}
        placeholder="Your Role"
      />
      <input
        type="text"
        name="hm"
        value={formData.hm}
        onChange={handleInputChange}
        placeholder="Hiring Manager"
      />
      <input
        type="text"
        name="portfolio"
        value={formData.portfolio}
        onChange={handleInputChange}
        placeholder="Portfolio URL"
      />
      <input
        type="text"
        name="fontSize"
        value={formData.fontSize}
        onChange={handleInputChange}
        placeholder="Font Size (e.g., 12pt)"
      />
      <input
        type="text"
        name="lineHeight"
        value={formData.lineHeight}
        onChange={handleInputChange}
        placeholder="Line Height (e.g., 20pt)"
      />
      {/* <input
        type="text"
        name="company"
        value={formData.company}
        onChange={handleInputChange}
        placeholder="Future Company"
      /> */}
    </form>
  )
}

export default CoverLetterForm
