import React, { useState } from 'react'
import '../scss/KeySetter.scss'
import { useOpenAI } from '../context/OpenAIKeyContext'
import { validateApiKey } from '../utils/gptUtils'

const KeySetter = ({ setPDFOnly }) => {
  const { setApiKey } = useOpenAI()
  const [value, setValue] = useState('')

  const handleInputChange = (e) => {
    setValue(e.target.value)
  }

  const handleSetKey = async () => {
    if (await validateApiKey(value)) setApiKey(value)
    else alert('Invalid API key')
  }

  return (
    <div className="key-setter-container">
      <h2 className="key-setter-title">Set Your OpenAI API Key</h2>
      <div className="input-wrapper">
        <input
          className="simple-input"
          type="text"
          value={value}
          onChange={handleInputChange}
          placeholder="Enter your API key here"
        />
        <button className="set-key-button" onClick={handleSetKey}>
          Set API Key
        </button>

        <button className="set-key-button" onClick={() => setPDFOnly(true)}>
          PDF Generator Only
        </button>
      </div>
    </div>
  )
}

export default KeySetter
