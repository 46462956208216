import { cl_template } from "../templates/templates"

function embedContentInTemplate (content) {
  try {
    let template = cl_template
    const result = template.replace('###Content###', content)
    return result
  } catch (error) {
    console.error("Error reading or processing the file:", error)
    return null
  }
}

async function generatePDFFromLatex (latexContent, name = 'coverLetter') {
  // URL encode the LaTeX content
  const encodedLatexContent = encodeURIComponent(latexContent)
  const url = `https://latexonline.cc/compile?text=${encodedLatexContent}&command=pdflatex`

  //open the url in a new tab
  window.open(url, '_blank')
}

export { embedContentInTemplate, generatePDFFromLatex }