import OpenAI from 'openai'
import React, { createContext, useContext, useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { validateApiKey } from '../utils/gptUtils'
import { APIClient } from 'openai/core.mjs'

const OpenAIKeyContext = createContext()

export const OpenAIKeyProvider = ({ children }) => {
  const [apiKey, setApiKey] = useState('')
  const [openai, setOpenai] = useState(null)

  useEffect(() => {
    const checkKey = async () => {
      let storedApiKey = process.env.REACT_APP_OPENAI_API_KEY
      if (await validateApiKey(storedApiKey)) {
        setApiKey(storedApiKey)
        return
      }
      storedApiKey = Cookies.get('openai_api_key')
      if (await validateApiKey(storedApiKey)) {
        setApiKey(storedApiKey)
      }
    }
    checkKey()
  }, [])

  useEffect(() => {
    if (!apiKey || apiKey == '') {
      setOpenai(null)
      return
    }
    try {
      const openaiInstance = new OpenAI({
        apiKey: apiKey,
        dangerouslyAllowBrowser: true,
      })
      setOpenai(openaiInstance)
      Cookies.set('openai_api_key', apiKey, { expires: 1 })
    } catch (e) {
      console.error('Error creating OpenAI instance:', e)
    }
  }, [apiKey])

  return (
    <OpenAIKeyContext.Provider value={{ openai, setApiKey }}>
      {children}
    </OpenAIKeyContext.Provider>
  )
}

export const useOpenAI = () => {
  return useContext(OpenAIKeyContext)
}
