const cl_template = `\\documentclass[10pt,letter]{letter}
\\usepackage[utf8]{inputenc}
\\NeedsTeXFormat{LaTeX2e}
\\ProvidesPackage{TLCcoverletter}[12/27/21 cover letter package]
\\RequirePackage[T1]{fontenc} 
\\RequirePackage[default,semibold]{sourcesanspro}
\\RequirePackage[12pt]{moresize}
\\usepackage{anyfontsize}
\\RequirePackage{csquotes}
\\RequirePackage[margin=.5in]{geometry}
\\setlength{\\parskip}{1em}
\\RequirePackage{xcolor}
\\definecolor{highlight}{RGB}{61, 90, 128}
\\RequirePackage{hyperref}
\\hypersetup{colorlinks=true,urlcolor=highlight}
\\newcommand{\\bold}[1]{ {\\bfseries #1}}
\\pagenumbering{gobble}
\\RequirePackage{standalone}
\\RequirePackage{import}
\\RequirePackage[english]{babel}
\\RequirePackage{blindtext}
\\def\\name{Xi Chen}
\\signature{\\name}
\\def\\phone{(804) 619-2088}
\\def\\email{xander11chan@gmail.com}
\\def\\LinkedIn{xi-chen-515156231}
\\def\\github{yingchenxing} 
\\def\\role{Software Engineer}
\\def\\hm{Hiring Manager}
\\def\\position{}
\\def\\company{}
\\def\\source{}
\\RequirePackage{fancyhdr}
\\fancypagestyle{plain}{%
\\fancyhf{}
\\lhead{\\phone \\\\ % PHONE
	    \\href{mailto:\\email}{\\email}} % EMAIL}
	\\chead{%
	    \\centering {\\Large \\bold\\name} \\\\%
	    {\\color{highlight} \\large{\\role}}}%
	    \\rhead{
	    Portfolio: \\href{https://11chen.link}{ 11chen.link}\\\\ % Portfolio
	    \\href{https://github.com/\\github}{github.com/\\github} \\\\% Github
	    \\href{https://www.linkedin.com/in/\\LinkedIn}{linkedin.com/in/\\LinkedIn}} % LinkedIn
\\renewcommand{\\headrulewidth}{2pt}% 2pt header rule
\\renewcommand{\\headrule}{\\hbox to\\headwidth{%
  \\color{highlight}\\leaders\\hrule height \\headrulewidth\\hfill}}
}
\\pagestyle{plain}

\\setlength{\\headheight}{90pt}
\\setlength{\\headsep}{0pt}

\\makeatletter
\\let\\ps@empty\\ps@plain
\\let\\ps@firstpage\\ps@plain
\\makeatother

\\renewcommand{\\opening}[1]{\\ifx\\@date\\empty\\else\\fi #1}
\\begin{document}
\\begin{letter}{}
\\opening{Dear \\hm,}
\\setlength\\parindent{.4in}

###Content###
\\closing{Sincerely,}
\\end{letter}
\\end{document}
`

const getLatexStr = ({ name, phone, email, linkedin, github, role, hm = "Hiring Manager", portfolio, fontSize = "12pt", lineHeight = "20pt", content }) => {

	const linkedinField = linkedin && linkedin != '' ? `\\href{https://${linkedin}}{${linkedin}}` : ''
	const githubField = github && github != '' ? `\\href{https://${github}}{${github}}` : ''
	const portfolioField = portfolio && portfolio != '' ? `Portfolio: \\href{https://${portfolio}}{${portfolio}}` : ''

	// Join fields with newlines
	const rightHeader = [portfolioField, githubField, linkedinField].filter(Boolean).join(' \\\\ ')

	return `\\documentclass[10pt,letter]{letter}
  \\usepackage[utf8]{inputenc}
  \\NeedsTeXFormat{LaTeX2e}
  \\ProvidesPackage{TLCcoverletter}[12/27/21 cover letter package]
  \\RequirePackage[T1]{fontenc} 
  \\RequirePackage[default,semibold]{sourcesanspro}
  \\RequirePackage[12pt]{moresize}
  \\usepackage{anyfontsize}
  \\RequirePackage{csquotes}
  \\RequirePackage[margin=.5in]{geometry}
  \\setlength{\\parskip}{1em}
  \\RequirePackage{xcolor}
  \\definecolor{highlight}{RGB}{61, 90, 128}
  \\RequirePackage{hyperref}
  \\hypersetup{colorlinks=true,urlcolor=highlight}
  \\newcommand{\\bold}[1]{ {\\bfseries #1}}
  \\pagenumbering{gobble}
  \\RequirePackage{standalone}
  \\RequirePackage{import}
  \\RequirePackage[english]{babel}
  \\RequirePackage{blindtext}
  \\def\\name{${name}}
  \\signature{\\name}
  \\def\\phone{${phone}}
  \\def\\email{${email}}
  \\def\\LinkedIn{${linkedin || ''}}
  \\def\\github{${github || ''}} 
  \\def\\role{${role}}
  \\def\\hm{${hm}}
  \\def\\portfolio{${portfolio || ''}}
  \\RequirePackage{fancyhdr}
  \\fancypagestyle{plain}{%
  \\fancyhf{}
  \\lhead{\\phone \\\\ % PHONE
    \\href{mailto:\\email}{\\email}} % EMAIL}
    \\chead{%
      \\centering {\\Large \\bold\\name} \\\\%
      {\\color{highlight} \\large{\\role}}}%
      \\rhead{%
      ${rightHeader}
    } % Right header with conditionals
  \\renewcommand{\\headrulewidth}{2pt}% 2pt header rule
  \\renewcommand{\\headrule}{\\hbox to\\headwidth{%
    \\color{highlight}\\leaders\\hrule height \\headrulewidth\\hfill}}
  }
  \\pagestyle{plain}
  
  \\setlength{\\headheight}{90pt}
  \\setlength{\\headsep}{0pt}
  
  \\makeatletter
  \\let\\ps@empty\\ps@plain
  \\let\\ps@firstpage\\ps@plain
  \\makeatother
  
  \\renewcommand{\\opening}[1]{\\ifx\\@date\\empty\\else\\fi #1}
  \\begin{document}
  \\fontsize{${fontSize}}{${lineHeight}}\\selectfont
  \\begin{letter}{}
  \\opening{Dear \\hm,}
	
  \\setlength\\parindent{.4in}
  ${content}
  \\closing{Sincerely,}
  \\end{letter}
  \\end{document}
  `
}

export { cl_template, getLatexStr }
