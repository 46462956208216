import { useState } from 'react'
import Home from './components/Home'
import KeySetter from './components/KeySetter'
import { useOpenAI } from './context/OpenAIKeyContext'

function App () {
  const { openai } = useOpenAI()
  const [PDFOnly, setPDFOnly] = useState(false)

  if (!PDFOnly && openai === null) {
    return (
      <KeySetter setPDFOnly={setPDFOnly} />
    )
  }

  console.log('OpenAI instance:', openai)

  return (
    <div className="App">
      <Home PDFOnly={PDFOnly} />
    </div>
  )
}

export default App
