const validateApiKey = async (apiKey) => {
  if (apiKey === undefined || apiKey === null || apiKey === '') {
    return false
  }
  try {
    const response = await fetch('https://api.openai.com/v1/models', {
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    })
    if (response.ok) {
      return true
    } else {
      return false
    }
  } catch (error) {
    return false
  }
}

export { validateApiKey }